@mixin table-shadow() {
  position: relative;
  background-color: $gray-900 !important;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  @if $enable-shadows {
    //@include box-shadow($btn-box-shadow, 0 10px 30px 5px rgba($black, .85));
  }
}

@mixin table-list-view() {
  .table {
    display: block;

    thead {
      display: none;
    }
    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      @include table-shadow();

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 2rem;

      td {
        border-top: none;
        border-bottom: none;
        flex-basis: auto;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        td {
          flex-basis: 50%;
        }
      }

      @include media-breakpoint-up(lg) {
        td {
          flex-basis: 33.3%;
        }
      }

      @include media-breakpoint-up(xxxl) {
        td {
          flex-basis: 25%;
        }
      }
    }
  }
}

@mixin pseudo-border-gradient() {
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 1px;
    @include gradient-x($pink, $peach);
  }
}
