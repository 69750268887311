// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import "mixins/custom";

// Import Bootstrap and its default variables
//@import '~bootstrap/scss/bootstrap';
@import 'bootstrap/imports';

// Utilities / helper classes
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";

// Other libraries
@import "custom/variables";
@import "custom/forms";
@import "custom/modal";
@import "custom/shapes";
@import "lib/react-select";
@import "~slick-carousel/slick/slick";
@import "lib/slick";

// Components
@import "components/page-transitions";
@import "components/rangeslider";
@import "components/dashboard";
@import "components/timeline";

@import "custom";
