// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: $navbar-padding-y $navbar-padding-x;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properties so that content nested within behave properly.
  %container-flex-properties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .container,
  .container-fluid {
    @extend %container-flex-properties;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    > .container#{breakpoint-infix($breakpoint, $container-max-widths)} {
      @extend %container-flex-properties;
    }
  }

  transition: background .2s ease-in-out;

  &.navbar-transparent {
    background: transparent !important;
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  background-color: rgba(0, 0, 0, .8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  &.navbar-transparent {
    background: transparent !important;
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  }
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  @include font-size($navbar-brand-font-size);
  line-height: inherit;
  white-space: nowrap;

  @include hover-focus() {
    text-decoration: none;
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
    font-weight: $font-weight-medium;
    color: $white;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}


// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  position: relative;
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  @include font-size($navbar-toggler-font-size);
  line-height: 1;
  background-color: transparent; // remove default button style
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus() {
    text-decoration: none;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: .75em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
  //border-top: 3px solid $primary;
  //border-bottom: 3px solid $primary;
  position: relative;
}

.navbar-toggler-span {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: block;

  webkit-transition: -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  transition: -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96), -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  -webkit-transform: none;
  transform: none;

  &.navbar-toggler-span-top {
    z-index: 4;

    span {
      top: 14px;
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
  }

  &.navbar-toggler-span-bottom {
    span {
      bottom: 14px;
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
  }

  span {
    display: block;
    width: 30px;
    height: 2px;
    background: $primary;
    border-radius: 0.5px;
    position: absolute;
    right: 0;
    z-index: 1;
    -webkit-transition: -webkit-transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s;
    transition: -webkit-transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s;
    transition: transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s;
    transition: transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s, -webkit-transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s;
  }
}

.opened .navbar-toggler-span {
  -webkit-transition: -webkit-transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s;
  transition: -webkit-transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s;
  transition: transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s;
  transition: transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s, -webkit-transform .3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1008s;
  -webkit-transform: rotate(
      45deg
  );
  transform: rotate(
      45deg
  );

  &.navbar-toggler-span-bottom {
    -webkit-transform: rotate(
        -45deg
    );
    transform: rotate(
        -45deg
    );
  }

  span {
    -webkit-transition: -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transition: -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transition: transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96), -webkit-transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    -webkit-transform: none;
    transform: none;
  }
}

.navbar-nav-scroll {
  max-height: $navbar-nav-scroll-max-height;
  overflow-y: auto;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        %container-navbar-expand-#{$breakpoint} {
          padding-right: 0;
          padding-left: 0;
        }

        > .container,
        > .container-fluid {
          @extend %container-navbar-expand-#{$breakpoint};
        }

        @each $size, $container-max-width in $container-max-widths {
          > .container#{breakpoint-infix($size, $container-max-widths)} {
            @extend %container-navbar-expand-#{$breakpoint};
          }
        }
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        %container-nesting-#{$breakpoint} {
          flex-wrap: nowrap;
        }

        > .container,
        > .container-fluid {
          @extend %container-nesting-#{$breakpoint};
        }

        @each $size, $container-max-width in $container-max-widths {
          > .container#{breakpoint-infix($size, $container-max-widths)} {
            @extend %container-nesting-#{$breakpoint};
          }
        }

        .navbar-nav-scroll {
          overflow: visible;
        }

        .navbar-collapse {
          display: flex !important; // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none;
        }
      }
    }
  }
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: $navbar-light-brand-color;

    @include hover-focus() {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus() {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-brand-color;

    @include hover-focus() {
      color: $navbar-dark-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus() {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-dark-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus() {
        color: $navbar-dark-active-color;
      }
    }
  }
}
