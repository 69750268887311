.shapes-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  canvas {
    z-index: -1 !important;
  }

  // @include media-breakpoint-down(md) {
  //   display: none;
  // }
}

.blurred-shape {
  position: absolute;
  filter: blur(120px);
  animation: blob 7s infinite;
  opacity: 1;
  //mix-blend-mode: multiply;
  border-radius: 9999px;
  top: 50%;
  left: 50%;
  z-index: -1;

  &.animation-none {
    animation: none;
  }
}

.shape-dark {
  background-color: #8E2298;
}

.shape-purple {
  background-color: #8D49FC;
}

.shape-pink {
  background-color: #FF94C8;
}

.animation-delay-2000 {
  animation-delay: 2s;
}
.animation-delay-4000 {
  animation-delay: 4s;
}

#main {
  .shape-purple {
    margin-top: 10px;
    margin-left: -550px;
  }
  .shape-pink {
    margin-top: 50px;
    margin-left: -200px;
  }
  .shape-dark {
    margin-top: -200px;
    margin-left: 200px;
  }
}

#about-us {
  .shape-purple.for-prev {
    margin-top: -580px;
    margin-left: -550px;
  }
  .shape-pink.for-prev {
    margin-top: -630px;
    margin-left: -200px;
  }
  .shape-dark.for-prev {
    margin-top: -680px;
    margin-left: 200px;
  }

  .shape-purple.main {
    margin-top: -50px;
    margin-left: 350px;
  }
  .shape-pink.main {
    margin-top: -150px;
    margin-left: 250px;
  }
  .shape-dark.main {
    margin-top: -50px;
    margin-left: 100px;
  }
}

#ecosystem {
  .shape-purple.for-prev {
    margin-top: -695px;
    margin-left: 350px;
  }
  .shape-dark.for-prev {
    margin-top: -695px;
    margin-left: 100px;
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}
