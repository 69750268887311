.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline::after {
  @include media-breakpoint-up(sm) {
    content: '';
    position: absolute;
    width: 6px;
    background: $primary;
    border-radius: 2px;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
}

.timeline-container {
  @include media-breakpoint-up(sm) {
    padding: 15px 30px;
    position: relative;
    background: inherit;
    width: 50%;

    &.left {
      left: 0;
    }

    &.right {
      left: 50%;

      &::after {
        left: -13px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      //background-image: url("data:image/svg+xml,<svg viewBox='0 0 1011 410'><path transform='rotate(12 -177 -521)' d='m25 160-22-110 84-74 106 36 22 110-84 74z' style='fill:#{$primary}'/></svg>");
      //background-size: cover;
      width: 26px;
      height: 26px;
      top: calc(50% - 13px);
      right: -13px;
      background: $primary;
      border: 3px solid $gray-900;
      border-radius: 10px;
      z-index: 1;
    }
  }
}

.timeline-container .date {
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: left;
    margin-bottom: .5rem;
  }

  @include media-breakpoint-up(sm) {
    position: absolute;
    display: inline-block;
    top: calc(50% - 18px);
    text-align: center;
    width: 300px;
    padding: 0 20px;
  }
}

.timeline-container.left .date {
  @include media-breakpoint-up(sm) {
    left: 100%;
    text-align: left;
  }
}

.timeline-container.right .date {
  @include media-breakpoint-up(sm) {
    right: 100%;
    text-align: right;
  }
}

.timeline-container .content {
  @extend .card;
  padding: 1rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(sm) {
    text-align: right;

    ul {
      padding-left: 0;
      padding-right: 1.5em;

      li {
        direction: rtl;

        span {
          direction: ltr;
        }
      }
    }
  }
}

.timeline-container.right .content {
  @include media-breakpoint-up(sm) {
    text-align: left;

    ul {
      padding-left: 1.5em;
      padding-right: 0;

      li {
        direction: ltr;
      }
    }
  }
}
