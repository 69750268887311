html,
body {
  height: 100%;
}

#root {
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

.flex {
  display: flex;
}

.nav-link:hover {
  color: #FCD349;
}

#login-form,#reset-form,#register-form {
  position: relative;
  margin: auto;
}

.footer-link {
    // background-image: linear-gradient(to right, #F3B658 0%, #CF00A3 100%);
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
      &:hover {
        color: #fff;
      }
      svg {
        width: 20px!important;
      }
}

.react-tel-input {
  .form-control {
    font-size: $font-size-base !important;
    height: $input-height !important;
    padding: $input-padding-y $input-padding-x !important;
    padding-left: 50px !important;
    line-height: $input-line-height !important;
    border-radius: $input-border-radius !important;
    border-color: $input-border-color !important;
    border-width: $input-border-width !important;
    color: $input-color !important;
    width: 100% !important;

    &.form-control-lg {
      height: $input-height-lg !important;
      padding: $input-padding-y-lg $input-padding-x-lg !important;
      line-height: $input-line-height-lg !important;
      border-radius: $input-border-radius-lg !important;
      padding-left: calc(50px + 1rem) !important;
    }

    &:focus {
      border-color: $input-focus-border-color !important;
      & + .flag-dropdown {
        border-color: $input-focus-border-color !important;
      }
    }
  }

  .flag-dropdown {
    background-color: $input-bg !important;
    border-color: $input-border-color !important;
    border-top-left-radius: $input-border-radius !important;
    border-bottom-left-radius: $input-border-radius !important;
    border-width: $input-border-width !important;
    @include transition($input-transition);

    &:hover,
    &:focus,
    .selected-flag:focus,
    .selected-flag:hover,
    &.open,
    &.open .selected-flag {
      background: $gray-900 !important;
      border-color: $input-focus-border-color !important;
    }
  }

  .form-control-lg + .flag-dropdown {
    border-top-left-radius: $input-border-radius-lg !important;
    border-bottom-left-radius: $input-border-radius-lg !important;
    padding-left: 1rem !important;
  }

  .selected-flag {
    border-top-left-radius: $input-border-radius-lg !important;
    border-bottom-left-radius: $input-border-radius-lg !important;

    .arrow {
      border-top-color: $gray-600 !important;
    }
  }

  .country-list {
    background-color: $dark !important;
    border-radius: $border-radius-sm !important;
    border: $dropdown-border-width solid $dropdown-border-color;
  }
}

.react-tel-input .form-control {
  background-color: $input-bg !important;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: $gray-800 !important;
}

.language-switcher {
  button {
    @extend .btn;
    @extend .btn-link;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    color: $white;
    box-shadow: none !important;
    font-weight: $font-weight-medium;

    &:hover,
    &.active {
      color: $primary;
      text-decoration: none;
    }
    &.active {
      font-weight: $font-weight-bold;
    }
  }

  #language-switcher-list {
    background: #222222;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 160px;
    border-radius: 1rem;
    float: right;
    margin-left: -2.5rem !important;

    .dropdown-item {
      color: #ffffff;
    }

    .dropdown-item:hover {
      background: #383838;
    }
  }


}

.pt-navbar {
  padding-top: 120px !important;
}

$how-it-works-video-height: 170px;
$how-it-works-video-height-lg: 280px;
$how-it-works-video-width: 300px;
$how-it-works-video-width-lg: 500px;

#main-video {
  //padding-bottom: $how-it-works-video-height;
  //padding-top: 1px;
  //
  //@include media-breakpoint-up(md) {
  //  padding-bottom: $how-it-works-video-height-lg;
  //}

  position: relative;
  z-index: 1;
  width: $how-it-works-video-width;
  height: $how-it-works-video-height;
  //margin-left: $how-it-works-video-width / -2;

  iframe {
    width: $how-it-works-video-width - 4px;
    height: $how-it-works-video-height - 4px;
  }

  @include media-breakpoint-up(md) {
    width: $how-it-works-video-width-lg * 0.75;
    height: $how-it-works-video-height-lg * 0.75;
    //margin-left: $how-it-works-video-width-lg / -2;

    iframe {
      width: ($how-it-works-video-width-lg - 4px) * 0.75;
      height: ($how-it-works-video-height-lg - 4px) * 0.75;
    }
  }

  @include media-breakpoint-up(lg) {
    width: $how-it-works-video-width-lg;
    height: $how-it-works-video-height-lg;
    //margin-left: $how-it-works-video-width-lg / -2;

    iframe {
      width: $how-it-works-video-width-lg - 4px;
      height: $how-it-works-video-height-lg - 4px;
    }
  }
}

.input-group-select-icon {
  pointer-events: none;
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 10;
  color: $input-group-addon-color;
}

.input-group:hover {
  .input-group-select-icon {
    color: $white;
  }
}

.appearance-none {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.pagination {
  li {
    margin: 0 .5rem;

    &.active {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.card .preloader {
  @include border-radius($card-border-radius);
}

.cur-pointer {
  cursor: pointer;
}

.checkbox-toggle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0;
}

.hamburger {
  z-index: 1;
  width: 45px;
  height: 35px;
  cursor: pointer;
  transition: box-shadow 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  align-items: center;
  justify-content: center;
  display: none;
  background: none;
  border: none;
  outline: none;
  padding: 0 .5em;

  @include media-breakpoint-down(sm) {
    display: flex;
  }

  > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #FEFEFE;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -10px;
      left: 0;
      width: 100%;
      height: 2px;
      background: inherit;
      transition: all 0.4s ease;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 2px;
      background: inherit;
      transition: all 0.4s ease;
      top: 10px;
    }
  }
}

.open-nav {
  overflow:hidden;

  .hamburger > div {
    &:before {
      top: 0;
      transform: rotate(90deg);
    }

    &:after {
      top: 0;
      transform: rotate(90deg);
      opacity: 0;
    }

    transform: rotate(135deg);
  }

  .overlay {
    display: block;
    position: absolute;
    padding: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
  }

  #sidenav {
    left: 0;
  }
}

//#register-confirm,
//#footer {
//  @include media-breakpoint-down(sm) {
//    padding-bottom: 100px !important;
//  }
//}

#payment-methods-list {
  position: relative;

  @include media-breakpoint-down(lg) {
    width: calc(100% + 70px);
    overflow-x: auto;
    margin-left: -40px;
  }
}

#payment-methods-track {
  position: relative;
  .card {
    margin: 25px 50px 50px 0;
  }
  width: 100% !important;
}

.input-group-select-icon {
  pointer-events: none;
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 10;
  color: $input-group-addon-color;
}

.payment-method-title {
  min-height: 85px;
}

.clickable {
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  cursor: pointer;
}

#road-map {
  svg {
    width: 100%;
    height: auto;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #road-map-wrap {
    position: relative;
    width: 100%;
  }

  #road-map-text {
    @include media-breakpoint-up(md) {
      .road-map-quarter {
        position: absolute;
      }

      #road-map-q1 {
        top: 0;
        left: 76.6%;
      }

      #road-map-q2 {
        top: 0;
        left: 60.3%;
      }

      #road-map-q3 {
        top: 0;
        left: 44%;
      }

      #road-map-q4 {
        top: 0;
        left: 28%;
      }

      #road-map-q5 {
        top: 0;
        left: 11.6%;
      }

      #road-map-q6 {
        top: 60%;
        left: 11.6%;
      }

      #road-map-q7 {
        top: 60%;
        left: 28%;
      }

      #road-map-q8 {
        top: 60%;
        left: 44%;
      }

      #road-map-q9 {
        top: 60%;
        left: 60.3%;
      }

      #road-map-q10 {
        top: 60%;
        left: 76.6%;
      }

      .road-map-list {
        position: absolute;
        padding-left: 0;

        li {
          margin-bottom: .65rem;
        }

        &#list-1 {
          top: 18.6%;
          left: 77.7%;
          font-size: 85%;
          width: 18%;
        }

        &#list-2 {
          top: 18.6%;
          left: 61.5%;
          font-size: 85%;
          width: 12.5%;
        }

        &#list-3 {
          top: 18.6%;
          left: 45.3%;
          font-size: 85%;
          width: 13%;
        }

        &#list-4 {
          top: 18.6%;
          left: 28.9%;
          font-size: 85%;
          width: 14%;
        }

        &#list-5 {
          top: 18.6%;
          left: 12.7%;
          font-size: 85%;
          width: 13%;
        }

        &#list-6 {
          top: 79.2%;
          left: 12.7%;
          font-size: 85%;
          width: 14%;
        }

        &#list-7 {
          top: 79.2%;
          left: 28.9%;
          font-size: 85%;
          width: 14%;
        }

        &#list-8 {
          top: 79.2%;
          left: 45.3%;
          font-size: 85%;
          width: 14%;
        }

        &#list-9 {
          top: 79.2%;
          left: 61.5%;
          font-size: 85%;
          width: 14%;
        }

        &#list-10 {
          top: 79.2%;
          left: 77.7%;
          font-size: 85%;
          width: 12%;
        }
      }
    }
  }
}

#partners-list {
  .card {
    flex-basis: 150px;
    max-width: 150px;

    img {
      max-height: 60px;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 220px;
      max-width: 220px;

      img {
        max-height: 80px;
      }
    }
  }
}

.read-more {
  svg {
    transition: all .2s ease;
  }

  &:hover {
    text-decoration: none;

    svg {
      transition: all .2s ease;
      margin-left: 1rem !important;
    }
  }
}

.accordion-item {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;

    @include gradient-x(#DC8D17, #FCD349);
  }
}

.accordion-link {
  padding: 1.25rem 0;
  color: $white;
  width: 100%;
  font-weight: 700;
  font-size: 18px !important;
  transition: none;

  svg {
    font-size: 2rem;

    g {
      transition: transform .2s ease;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }


  &:hover {
    color: $peach;

    svg {
      color: $peach;
    }
  }
}

.absolute-stretch {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

#support-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: $telegram;
  color: $white;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
}


.docAndVideoMain {
  height: fit-content;
  padding: 20px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU5IiBoZWlnaHQ9IjE1OSIgdmlld0JveD0iMCAwIDE1OSAxNTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDE1OC42MzVWMC4wMDA4MDg3MTZIMTU4LjYzNEwwIDE1OC42MzVaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTAzN18zNDUpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTAzN18zNDUiIHgxPSI3OS4zMTciIHkxPSIxNTguNjM1IiB4Mj0iLTQ2LjcyOTEiIHkyPSIwLjAwMDgxMTAyOCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkZCODAxIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGQjgwMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=), url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU5IiBoZWlnaHQ9IjE1OSIgdmlld0JveD0iMCAwIDE1OSAxNTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNTguODkxIDAuMzY1MjM0VjE1OC45OTlIMC4yNTY2NjhMMTU4Ljg5MSAwLjM2NTIzNFoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xMDM3XzM0NykiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xMDM3XzM0NyIgeDE9Ijc5LjU3MzYiIHkxPSIwLjM2NTIzNCIgeDI9IjIwNS42MiIgeTI9IjE1OC45OTkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzk4NkUwMyIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGRjVDMDAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K);
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
}

.docAndVideo {
  padding: 2px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNzYiIHZpZXdCb3g9IjAgMCA2OCA3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTY2LjYwMTYgNzUuMzAwOEw2Ni42MDE2IDEuMDU2NDFMMC4xOTY2NTUgMS4wNTY0MSIgc3Ryb2tlPSIjQzQ2NzAyIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==), url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNzYiIHZpZXdCb3g9IjAgMCA2OCA3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDU0NjkgMC4wMDM5MDYyNVY3NC4yNDgzSDY3LjQ1OTYiIHN0cm9rZT0iI0ZGRDYwMCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  position: relative;
}

.docAndVideo img {
  width: 100%;
}

.docAndVideo svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.docAndVideo svg path {
  transition: all .2s;
}

.docAndVideo svg:hover path {
  fill-opacity: .8;
}

.padding-bottom-title {
  padding-bottom: 1rem;
}

@media screen and (max-width: 1120px) {
  .padding-top-dv {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 1050px) {
  .padding-top-dv {
    padding-top: 14rem;
  }
}

@media screen and (max-width: 820px) {
  .padding-top-dv {
    padding-top: 17rem;
  }
}

@media screen and (max-width: 767.5px) {
  .gap-for-video {
    gap: 40px;
  }

  .padding-top-dv {
    padding-top: 0;
  }
}