body .react-select {
  .react-select__control {
    @extend .form-control;

    width: 100%;
    display: flex;
    min-height: auto;
    padding: 0;

    .react-select__value-container {
      //padding: 0;
      min-height: auto;
      //position: initial;
    }

    .react-select__indicator-separator {
      background-color: $gray-800;
    }
  }

  .react-select__menu {
    background: none !important;
    //border: none !important;
    overflow: hidden;
    border-radius: $input-border-radius !important;
    border-color: $input-border-color !important;
    border-width: $input-border-width !important;
    border-style: solid;
    @include transition($input-transition);

    .react-select__menu-list {
      background-color: $input-bg !important;
      //border-radius: $input-border-radius !important;
      box-sizing: border-box;
      padding: 0 !important;

      .react-select__option {
        background-color: $input-bg;
        color: $input-color;

        &:hover {
          background-color: $gray-900;
        }
      }
    }
  }
}

.input-group {
  .react-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;

    &:not(:first-child) {
      .react-select__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
